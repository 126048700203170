const page_items_selections = `
                id
                code
                publicToken
                organization
                organizationCode
                organizationName
                name
                allowedParticipants
                bookTickets
                ticketPriceAmount
                ticketPriceCurrency
                tickets {
                    cursor
                    count
                    items {
                        id
                    }
                }
                godfatherBonusImage {
                    url
                    available
                }
                godfatherBonusPresentation
                plannedTickets
                createdAt
                updatedAt
                status
                publicPageShortUrl
                statTickets
                statSales
                statPaidDonations
                statCustomerCollectedAmount
                statMarketPlaceFeeAmount
                saleMandatoryOptInMessage
                mainCss {
                    available
                }
                mainJs {
                    available
                }
                statPaidSales
                statPaidAmount
                domain
                bannerImage {
                    url
                    available
                }
                mobileBannerImage {
                    url
                    available
                }
                tabletBannerImage {
                    url
                    available
                }
                private
                permanent
                scope
                scopeLocale
                smssender
                smssenderName
                smssenderProvider
                emailsender
                emailsenderEmail
                emailsenderName
                emailsenderIdentityArn
                emailFromArn
                emailFromEmail
                emailFrom
                country
                locale
                plan
                planCode
                planName
`;

const page_selections = `cursor count items { ${page_items_selections} }`;

const find_page = `findGames(offset: $offset, limit: $limit) { ${page_selections} }`;
const search_page = `searchGames(offset: $offset, limit: $limit, sort: $sort) { cursor count items { ${page_items_selections} } }`;



export const CREATE_GAME = (gql: any) => gql`
    mutation($data: CreateGameInput!) {
        createGame(data: $data) {
            id
        }
    }
`;
export const GET_GAME = (gql: any) => gql`
    query($id: ID!) {
        getGame(id: $id) {
            id
            organization
            organizationName
            name
            fullName
            shortName
            legalName
            nin
            customPacks
            customBonuses
            customOwnerForm
            bookTickets
            ticketPriceAmount
            ticketPriceCurrency
            allowedParticipants
            seoLang
            seoKeywords
            seoTitle
            seoDescription
            seoSubject
            seoAbstract
            seoAuthor
            statBooks
            booksPaymentStatus
            customPublicPageUrl
            lastDayStats {
                sales {
                    hours {
                        values
                        labels
                    }
                }
                tickets {
                    hours {
                        values
                        labels
                    }
                }
                donations {
                    hours {
                        values
                        labels
                    }
                }
                paymentAmounts {
                    hours {
                        values
                        labels
                    }
                }
            }
            currentDayStats {
                sales {
                    hours {
                        values
                        labels
                    }
                }
                tickets {
                    hours {
                        values
                        labels
                    }
                }
                donations {
                    hours {
                        values
                        labels
                    }
                }
                paymentAmounts {
                    hours {
                        values
                        labels
                    }
                }
            }
            lastMonthStats {
                sales {
                    days {
                        values
                        labels
                    }
                }
                tickets {
                    days {
                        values
                        labels
                    }
                }
                donations {
                    days {
                        values
                        labels
                    }
                }
                paymentAmounts {
                    days {
                        values
                        labels
                    }
                }
            }
            currentMonthStats {
                sales {
                    days {
                        values
                        labels
                    }
                }
                tickets {
                    days {
                        values
                        labels
                    }
                }
                donations {
                    days {
                        values
                        labels
                    }
                }
                paymentAmounts {
                    days {
                        values
                        labels
                    }
                }
            }
            currentYearStats {
                sales {
                    months {
                        values
                        labels
                    }
                }
                tickets {
                    months {
                        values
                        labels
                    }
                }
                donations {
                    months {
                        values
                        labels
                    }
                }
                paymentAmounts {
                    months {
                        values
                        labels
                    }
                }
            }
            lastYearStats {
                sales {
                    months {
                        values
                        labels
                    }
                }
                tickets {
                    months {
                        values
                        labels
                    }
                }
                donations {
                    months {
                        values
                        labels
                    }
                }
                paymentAmounts {
                    months {
                        values
                        labels
                    }
                }
            }
            tickets {
                cursor
                count
                items {
                    id
                    code
                }
            }
            plannedTickets
            createdAt
            updatedAt
            status
            type
            code
            locale
            country
            supportedLocales
            public
            private
            permanent
            presentation
            description
            publicEmail
            publicPhone
            overline
            features
            plannedDrawnAt
            plannedStartedAt
            plannedClosedAt
            externalReference
            publicPageShortUrl
            publicManagementUrl
            ticketsImportPrivateUrl
            faviconImage {
                available
                url
            }
            appleTouchImage {
                available
                url
            }
            qrcodeLogoImage {
                available
                url
            }
            logoImage {
                available
                url
            }
            logoAltImage {
                available
                url
            }
            bannerImage {
                available
                url
            }
            mobileBannerImage {
                available
                url
            }
            tabletBannerImage {
                available
                url
            }
            mainImage {
                available
                url
            }
            banner2Image {
                available
                url
            }
            backgroundImage {
                available
                url
            }
            facebookShareImage {
                available
                url
            }
            twitterShareImage {
                available
                url
            }
            flyerImage {
                available
                url
            }
            termsPdfFile {
                available
                url
            }
            godfatherBonusImage {
                url
                available
            }
            godfatherBonusPresentation
            saleMandatoryOptInMessage
            websiteUrl
            twitterUrl
            facebookUrl
            instagramUrl
            linkedinUrl
            youtubeUrl
            pinterestUrl
            tumblrUrl
            flickrUrl
            redditUrl
            tiktokUrl
            vimeoUrl
            snapchatUrl
            backgroundVideoUrl
            bannerVideoUrl
            banner2VideoUrl
            flyerVideoUrl
            mainVideoUrl
            mainShortVideoUrl
            mainLongVideoUrl
            salePaidVideoUrl
            salePromisedVideoUrl
            winnerVideoUrl
            looserVideoUrl
            participantVideoUrl
            promiseReminderVideoUrl
            promiseLastReminderVideoUrl
            mainCss {
                available
                content
            }
            mainJs {
                available
                content
            }
            mainQuote
            mainQuoteImage {
                available
                url
            }
            mainQuoteTitle
            mainQuoteImageTitle
            mainQuoteImageSubtitle
            domain
            notifyEmail
            mainTheme
            mainDfontfamily
            godfatherImage {
                available
                url
            }
            godfatherVideoUrl
            godfatherTitle
            godfatherSubtitle
            godfather2Image {
                available
                url
            }
            godfather2VideoUrl
            godfather2Title
            godfather2Subtitle
            godfather3Image {
                available
                url
            }
            godfather3VideoUrl
            godfather3Title
            godfather3Subtitle
            agenda
            warning
            locationMainImage {
                available
                url
            }
            locationLogoImage {
                available
                url
            }
            locationMainVideoUrl
            locationMainAudioUrl
            locationLatitude
            locationLongitude
            locationName
            locationCountry
            locationZipCode
            locationStreet
            locationComplement
            locationCity
            locationState
            locationPhone
            locationEmail
            locationWebsiteUrl
            locationFacebookUrl
            locationTwitterUrl
            locationYoutubeUrl
            locationPresentation
            locationDescription
            infos
            resultsPublishingStatus
            participantsNotificationStatus
            loosingParticipantsNotificationStatus
            winningParticipantsNotificationStatus
            presets
            varColorNavPrimary
            varColorNavSecondary
            varColorPrimary
            varColorSecondary
            varColorTextPrimary
            varColorTextSecondary
            varColorBorderPrimary
            varColorBorderSecondary
            varColorPanelPrimary
            varColorPanelSecondary
            varColorLogo
            varColorTitle
            varColorChoicePrimary
            varColorChoiceSecondary
            varColorFooterPrimary
            varColorFooterSecondary
            varColorHeading
            scope
            scopeLocale
            itemType
            smssender
            smssenderName
            smssenderProvider
            emailsender
            emailsenderEmail
            emailsenderName
            emailsenderIdentityArn
            emailFromArn
            emailFromEmail
            emailFrom
            publicPageEffects
            publicPageModules
            publicPageModulesData
            publicPageModulesCustomRaw
            publicPageModulesDiff
            bonusMessage
            publicPageBackgroundStyle
            publicPageBackgroundCustom
            publicPageBackgroundColor
            publicPageBackgroundImage {
                available
                url
            }
            publicPageBackgroundTexture
            publicPageBackgroundVideoUrl
            looserResultThanks
            looserResultMessage
            looserResultInfos
            looserResultWarning
            winnerResultThanks
            winnerResultMessage
            winnerResultInfos
            winnerResultWarning
            participantResultThanks
            participantResultMessage
            participantResultInfos
            participantResultWarning
            leaderboardEnabled
            godfathersRankingsEnabled
            sellergroupType
            sellergroupSortMode
            sellergroupGroupingsDisabled
            privacyShield
            mainLogoImage {
                available
                url
            }
            customResultsPageUrl
            customTermsPageUrl
            drawMode
            stripeCustomer
            plan
            statPaidBookpayments
            statBookpayments
            statBooks
            statDonationAverage
            statDonationRatio
            statPaidDonationCount
            statPaidDonations
            statPaidDonationsAmount
            statTickets
            statTicketsAverage
            statPaidTickets
            statPaidTicketsAmount
            statPaidSales
            statSales
            statSellergroups
            statSellers
            statGattributables
            statPaidAmount
            statPaymentAmountAverage
            statPaymentProviderFeeAmount
            statPaymentProviderRatio
            statRunningDuration
            statGoalRatio
            statMarketPlaceFeeAmount
            statCustomerCollectedAmount
            statTransactionFeeAmount
            statTotalFeeAmount
            statCustomerCollectedAmountFlex
            statMarketPlaceFeeAmountFlex
            statPaidAmountFlex
            statPaidDonationCountFlex
            statPaidDonationsAmountFlex
            statPaidDonationsFlex
            statPaidSalesFlex
            statPaidTicketsAmountFlex
            statPaidTicketsFlex
            statPaymentProviderFeeAmountFlex
            statTotalFeeAmountFlex
            statTransactionFeeAmountFlex
            publicPagePublishingStatus
            flexModeStatus
            adStatus
        }
    }
`;
export const GET_GAME_BY_CODE = (gql: any) => gql`
    query($code: String!) {
        getGameByCode(code: $code) {
            id
            code
            name
            organization
            organizationName
        }
    }
`;
export const FIND_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        ${find_page}
    }
`;
export const SEARCH_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        ${search_page }
    }
`;
export const SEARCH_RUNNING_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchRunningGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "RUNNING"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_WITHSALES_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "statPaidSales:desc") {
        searchWithSalesGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "statPaidSales", type: "int", operator: "gt", intValue: 0}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_RUNNINGWITHSALES_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "statPaidAmount:desc") {
        searchRunningWithSalesGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "RUNNING"},
                {field: "statPaidSales", type: "int", operator: "gt", intValue: 0}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_TOP_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "statPaidAmount:desc") {
        searchTopGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "statPaidSales", type: "int", operator: "gt", intValue: 0}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_CREATED_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCreatedGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "CREATED"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_VALIDATIONREQUESTED_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchValidationRequestedGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "VALIDATION_REQUESTED"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_VALIDATED_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchValidatedGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "VALIDATED"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_COMPLETED_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCompletedGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "COMPLETED"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_PAUSED_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPausedGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "PAUSED"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_DATEREACHED_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDateReachedGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "DATE_REACHED"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_CANCELED_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCanceledGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "CANCELED"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_CANCELLATIONREQUESTED_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCancellationRequestedGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "CANCELLATION_REQUESTED"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_CLOSED_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchClosedGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "CLOSED"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_DRAWNREQUESTED_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDrawnRequestedGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "DRAWN_REQUESTED"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_DRAWN_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDrawnGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "DRAWN"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_FINISHED_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchFinishedGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "FINISHED"}
            ]
        }) { ${page_selections} }
    }
`;

export const SEARCH_FREETICKET_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchFreeTicketGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "ticketPriceAmount", type: "int", operator: "eq", intValue: 0}
            ]
        }) { ${page_selections} }
    }
`;

export const SEARCH_HELLOASSO_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchHelloassoGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "features", type: "string", operator: "eq", value: "helloasso"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_ASSOFREE_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAssofreeGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "planCode", type: "string", operator: "eq", value: "assofree"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_ASSOFREE1K_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAssofree1kGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "planCode", type: "string", operator: "eq", value: "assofree1k"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_CUSTOM50K_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCustom50kGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "planCode", type: "string", operator: "eq", value: "custom50k"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_FLEX_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchFlexGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "flexModeStatus", type: "string", operator: "wildcard", value: "ACTIVATED"}
            ]
        }) { ${page_selections} }
    }
`;
export const SEARCH_ADS_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAdsGames: searchGames(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "adStatus", type: "string", operator: "wildcard", value: "ACTIVATED"}
            ]
        }) { ${page_selections} }
    }
`;

export const SEARCH_ALL_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAllGames: ${search_page}
    }
`;
export const FIND_ALL_GAMES = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findAllGames: ${find_page}
    }
`;
