const t = {
    menus: {
        main: { section: 'activity', priority: 30, icon: 'fa-dice' },
    },
    new: {
        attributes: [
            'type', 'name', 'bookTickets', 'ticketPriceAmount', 'ticketPriceCurrency',
            'plannedTickets', 'organization',
            'presentation', 'description',
            'plannedDrawnAt', 'plannedStartedAt', 'plannedClosedAt',
            'logoImage', 'qrcodeLogoImage', 'mainCss', 'mainJs', 'mainImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'youtubeUrl',
            'mainVideoUrl',
            'faviconImage', 'appleTouchImage',
            'publicEmail', 'publicPhone', 'domain',
            'customPacks', 'customBonuses', 'customOwnerForm', 'notifyEmail', 'mainTheme', 'mainDfontfamily',
            'agenda', 'infos', 'warning', 'supportedLocales', 'locale', 'country', 'permanent',
            'allowedParticipants', 'presets', 'scope', 'scopeLocale',
            'varColorNavPrimary', 'varColorNavSecondary', 'varColorPrimary', 'varColorSecondary', 'varColorPanelPrimary', 'varColorPanelSecondary',
            'varColorTextPrimary', 'varColorTextSecondary', 'varColorBorderPrimary', 'varColorBorderSecondary', 'varColorLogo', 'varColorTitle',
            'varColorChoicePrimary', 'varColorChoiceSecondary', 'varColorFooterPrimary', 'varColorFooterSecondary', 'varColorHeading', 'itemType', 'sellergroupType',
            'smssender', 'emailsender', 'emailFromArn', 'emailFromEmail', 'emailFrom', 'publicPageModulesData', 'publicPageModulesCustomRaw', 'publicPageModulesDiff', 'bonusMessage', 'publicPagesModules', 'publicPageEffects',
            'publicPageBackgroundStyle', 'publicPageBackgroundCustom', 'publicPageBackgroundColor', 'publicPageBackgroundImage', 'publicPageBackgroundTexture', 'publicPageBackgroundVideoUrl',
            'seoLang', 'seoKeywords', 'seoTitle', 'seoDescription', 'seoSubject', 'seoAbstract', 'seoAuthor', 'leaderboardEnabled', 'godfathersRankingsEnabled',
            'sellergroupSortMode', 'sellergroupGroupingsDisabled', 'customPublicPageUrl', 'privacyShield', 'mainLogoImage', 'customResultsPageUrl', 'customTermsPageUrl', 'drawMode',
            'stripeCustomer', 'plan', 'flexModeStatus', 'godfatherBonusImage', 'godfatherBonusPresentation',
        ],
        content: [
            ['tabs', [
                ['edit_game_form_general_title', [
                    ['row', [
                        ['tabs', [
                            ['edit_game_form_presentation_title', [
                                '!$name!', '$organization!', '$presentation', '$description',
                                '$locale', '$country', '$supportedLocales', '$scope', '$scopeLocale',
                                '$publicEmail', '$publicPhone', '$gamePresets', '$customResultsPageUrl', '$customTermsPageUrl', '$drawMode',
                                '$stripeCustomer',
                            ]],
                            ['edit_game_form_images_title', [
                                '$logoImage', '$qrcodeLogoImage', '$bannerImage', '$mobileBannerImage', '$tabletBannerImage', '$faviconImage', '$appleTouchImage', '$publicPageBackgroundImage', '$mainLogoImage', '$godfatherBonusImage',
                            ]],
                            ['edit_game_form_videos_title', [
                                '$mainVideoUrl',
                                '$backgroundVideoUrl',
                                '$bannerVideoUrl',
                                '$banner2VideoUrl',
                                '$flyerVideoUrl',
                                '$mainVideoUrl',
                                '$mainQuoteVideoUrl',
                                '$mainShortVideoUrl',
                                '$mainLongVideoUrl',
                                '$salePaidVideoUrl',
                                '$salePromisedVideoUrl',
                                '$winnerVideoUrl',
                                '$looserVideoUrl',
                                '$participantVideoUrl',
                                '$promiseReminderVideoUrl',
                                '$promiseLastReminderVideoUrl',
                                '$publicPageBackgroundVideoUrl',
                            ]],
                            ['edit_game_form_seo_title', [
                                '$seoLang', '$seoKeywords', '$seoTitle', '$seoDescription', '$seoSubject', '$seoAbstract', '$seoAuthor',
                            ]],
                        ]],
                        ['tabs', [
                            ['edit_game_form_fundraising_title', [
                                '$gameType', '$plan', '$allowedParticipants', '$permanent', '$bookTickets', '$ticketPriceAmount', '$plannedTickets',
                                '$ticketPriceCurrency', '$domain', '$notifyEmail', '$mainTheme', '$mainDfontfamily', '$itemType', '$gameSellergroupType',
                                '$sellergroupSortMode', '$sellergroupGroupingsDisabled',
                                '$leaderboardEnabled', '$godfathersRankingsEnabled', '$privacyShield', '$flexModeStatus'
                            ]],
                            ['edit_game_form_advanced_title', [
                                '$nin', '$fullName', '$shortName', '$legalName',
                                '$plannedDrawnAt', '$plannedStartedAt', '$plannedClosedAt',
                                '$externalReference',
                                '$saleMandatoryOptInMessage',
                                '$mainQuote', '$mainQuoteTitle', '$mainQuoteImageTitle', '$mainQuoteImageSubtitle',
                                '$customPacks', '$customBonuses', '$customOwnerForm',
                                '$agenda', '$infos', '$warning', '$smssender', '$emailsender', '$emailFromArn', '$emailFromEmail', '$emailFrom',
                                '$publicPageModules',
                                '$publicPageModulesData', '$publicPageModulesCustomRaw', '$publicPageModulesDiff', '$bonusMessage', '$publicPageEffects',
                                '$publicPageBackgroundStyle', '$publicPageBackgroundCustom', '$publicPageBackgroundColor', '$publicPageBackgroundTexture',
                                '$customPublicPageUrl',
                            ]],
                            ['edit_organization_form_socials_title', [
                                '$websiteUrl', '$facebookUrl', '$twitterUrl', '$youtubeUrl',
                                '$instagramUrl', '$linkedinUrl', '$pinterestUrl', '$flickrUrl', '$vimeoUrl', '$tumblrUrl',
                                '$tiktokUrl', '$snapchatUrl', '$redditUrl',
                            ]],
                        ]]
                    ]]
                ]],
                ['edit_game_form_style_title', [
                    ['row', [
                        ['tabs', [
                            ['edit_game_form_css_title', [
                                '$mainCss',
                            ]],
                            ['edit_game_form_colors_title', [
                                ['column', [
                                    '$varColorPrimary',
                                    '$varColorSecondary',
                                    '$varColorNavPrimary',
                                    '$varColorNavSecondary',
                                    '$varColorTextPrimary',
                                    '$varColorTextSecondary',
                                    '$varColorBorderPrimary',
                                    '$varColorBorderSecondary',
                                    '$varColorPanelPrimary',
                                    '$varColorPanelSecondary',
                                    '$varColorChoicePrimary',
                                    '$varColorChoiceSecondary',
                                    '$varColorFooterPrimary',
                                    '$varColorFooterSecondary',
                                    '$varColorTitle',
                                    '$varColorHeading',
                                    '$varColorLogo',
                                ]],
                            ]],
                        ]],
                    ]]
                ]],
                ['edit_game_form_code_title', [
                    ['row', [
                        '$mainJs',
                    ]]
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'type', 'name', 'bookTickets', 'ticketPriceAmount', 'ticketPriceCurrency',
            'plannedTickets', 'suspended', 'organization', 'locale',
            'country', 'public', 'private', 'permanent', 'presentation', 'description',
            'features', 'plannedDrawnAt', 'plannedStartedAt', 'plannedClosedAt',
            'saleMandatoryOptInMessage',
            'logoImage', 'qrcodeLogoImage', 'logoAltImage', 'mainCss', 'mainJs', 'mainImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'banner2Image', 'backgroundImage',
            'flyerImage', 'twitterShareImage', 'facebookShareImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'instagramUrl', 'linkedinUrl', 'youtubeUrl', 'pinterestUrl',
            'tumblrUrl', 'flickrUrl', 'redditUrl', 'tiktokUrl', 'vimeoUrl', 'snapchatUrl',
            'backgroundVideoUrl', 'bannerVideoUrl', 'banner2VideoUrl', 'flyerVideoUrl', 'mainVideoUrl',
            'mainShortVideoUrl', 'mainLongVideoUrl', 'salePaidVideoUrl', 'salePromisedVideoUrl',
            'winnerVideoUrl', 'looserVideoUrl', 'participantVideoUrl', 'promiseReminderVideoUrl', 'promiseLastReminderVideoUrl',
            'mainQuote', 'mainQuoteImage', 'mainQuoteTitle', 'faviconImage', 'appleTouchImage',
            'overline', 'publicEmail', 'publicPhone', 'nin', 'fullName', 'shortName', 'legalName', 'domain',
            'termsPdfFile', 'customPacks', 'customBonuses', 'customOwnerForm', 'mainQuoteImageTitle', 'mainQuoteImageSubtitle', 'notifyEmail', 'mainTheme', 'mainDfontfamily',
            'godfatherImage', 'godfatherVideoUrl', 'godfatherTitle', 'godfatherSubtitle', 'godfather2Image',
            'godfather2VideoUrl', 'godfather2Title', 'godfather2Subtitle', 'godfather3Image', 'godfather3VideoUrl',
            'godfather3Title', 'godfather3Subtitle', 'agenda', 'warning', 'locationMainImage', 'locationMainVideoUrl',
            'locationMainAudioUrl', 'locationLatitude', 'locationLongitude', 'locationName', 'locationCountry', 'locationZipCode',
            'locationStreet', 'locationComplement', 'locationCity', 'locationState', 'locationPhone', 'locationEmail',
            'locationWebsiteUrl', 'locationFacebookUrl', 'locationTwitterUrl', 'locationYoutubeUrl', 'mainQuoteVideoUrl',
            'locationPresentation', 'locationDescription', 'locationLogoImage', 'infos', 'supportedLocales',
            'allowedParticipants', 'scope', 'scopeLocale', 'godfatherBonusImage', 'godfatherBonusPresentation',
            'varColorNavPrimary', 'varColorNavSecondary', 'varColorPrimary', 'varColorSecondary', 'varColorPanelPrimary', 'varColorPanelSecondary',
            'varColorTextPrimary', 'varColorTextSecondary', 'varColorBorderPrimary', 'varColorBorderSecondary', 'varColorLogo', 'varColorTitle',
            'varColorChoicePrimary', 'varColorChoiceSecondary', 'varColorFooterPrimary', 'varColorFooterSecondary', 'varColorHeading', 'itemType', 'sellergroupType',
            'sellergroupSortMode', 'sellergroupGroupingsDisabled',
            'smssender', 'emailsender', 'emailFromArn', 'emailFromEmail', 'emailFrom', 'publicPageModulesData', 'bonusMessage', 'publicPageModules', 'publicPageModulesCustomRaw', 'publicPageModulesDiff', 'publicPageEffects',
            'publicPageBackgroundStyle', 'publicPageBackgroundCustom', 'publicPageBackgroundColor', 'publicPageBackgroundImage', 'publicPageBackgroundTexture', 'publicPageBackgroundVideoUrl',
            'looserResultThanks', 'looserResultMessage', 'looserResultInfos', 'looserResultWarning', 'winnerResultThanks', 'winnerResultMessage',
            'winnerResultInfos', 'winnerResultWarning', 'participantResultThanks', 'participantResultMessage', 'participantResultInfos', 'participantResultWarning',
            'seoLang', 'seoKeywords', 'seoTitle', 'seoDescription', 'seoSubject', 'seoAbstract', 'seoAuthor', 'leaderboardEnabled', 'godfathersRankingsEnabled',
            'customPublicPageUrl', 'privacyShield', 'mainLogoImage', 'customResultsPageUrl', 'customTermsPageUrl', 'drawMode', 'stripeCustomer', 'plan',  'flexModeStatus',
        ],
        content: [
            ['tabs', [
                ['edit_game_form_general_title', [
                    ['row', [
                        ['tabs', [
                            ['edit_game_form_presentation_title', [
                                '$code:readonly!', '!$name!', '$organization!', '$overline', '$presentation', '$description',
                                '$locale!', '$country!', '$supportedLocales', '$scope', '$scopeLocale', '$publicEmail', '$publicPhone', '$termsPdfFile', '$customResultsPageUrl', '$customTermsPageUrl', '$drawMode',
                                '$stripeCustomer',
                            ]],
                            ['edit_game_form_images_title', [
                                '$logoImage', '$qrcodeLogoImage', '$logoAltImage', '$bannerImage', '$mobileBannerImage', '$tabletBannerImage', '$banner2Image', '$faviconImage', '$appleTouchImage',
                                '$mainImage', '$backgroundImage', '$flyerImage', '$twitterShareImage', '$facebookShareImage',
                                '$mainQuoteImage', '$publicPageBackgroundImage', '$mainLogoImage', '$godfatherBonusImage',
                            ]],
                            ['edit_game_form_videos_title', [
                                '$mainVideoUrl', '$publicPageBackgroundVideoUrl',
                            ]],
                            ['edit_game_form_seo_title', [
                                '$seoLang', '$seoKeywords', '$seoTitle', '$seoDescription', '$seoSubject', '$seoAbstract', '$seoAuthor',
                            ]],
                            ['edit_game_form_messages_title', [
                                '$looserResultThanks', '$looserResultMessage', '$looserResultInfos', '$looserResultWarning', '$winnerResultThanks', '$winnerResultMessage',
                                '$winnerResultInfos', '$winnerResultWarning', '$participantResultThanks', '$participantResultMessage', '$participantResultInfos', '$participantResultWarning',
                            ]],
                        ]],
                        ['tabs', [
                            ['edit_game_form_fundraising_title', [
                                '$gameType!', '$plan', '$permanent', '$allowedParticipants', '$bookTickets!', '$ticketPriceAmount!', '$plannedTickets!',
                                '$ticketPriceCurrency!', '$domain', '$notifyEmail', '$mainTheme', '$mainDfontfamily', '$itemType', '$gameSellergroupType',
                                '$sellergroupSortMode', '$sellergroupGroupingsDisabled',
                                '$leaderboardEnabled', '$godfathersRankingsEnabled', '$privacyShield',  '$flexModeStatus',
                            ]],
                            ['edit_game_form_advanced_title', [
                                '$nin', '$fullName', '$shortName', '$legalName',
                                '$plannedDrawnAt', '$plannedStartedAt', '$plannedClosedAt',
                                '$externalReference',
                                '$saleMandatoryOptInMessage',
                                '$mainQuote', '$mainQuoteTitle', '$mainQuoteImageTitle', '$mainQuoteImageSubtitle',
                                '$godfatherBonusPresentation',
                                '$customPacks', '$customBonuses', '$customOwnerForm',
                                '$agenda', '$infos', '$warning', '$smssender', '$emailsender', '$emailFromArn', '$emailFromEmail', '$emailFrom',
                                '$publicPageModules',
                                '$publicPageModulesData', '$publicPageModulesCustomRaw', '$publicPageModulesDiff', '$bonusMessage', '$publicPageEffects',
                                '$publicPageBackgroundStyle', '$publicPageBackgroundCustom', '$publicPageBackgroundColor', '$publicPageBackgroundTexture',
                                '$customPublicPageUrl',
                            ]],
                            ['edit_organization_form_socials_title', [
                                '$websiteUrl', '$facebookUrl', '$twitterUrl', '$youtubeUrl',
                            ]],
                        ]]
                    ]]
                ]],
                ['edit_game_form_style_title', [
                    ['row', [
                        ['tabs', [
                            ['edit_game_form_css_title', [
                                '$mainCss',
                            ]],
                            ['edit_game_form_colors_title', [
                                ['column', [
                                    '$varColorPrimary',
                                    '$varColorSecondary',
                                    '$varColorNavPrimary',
                                    '$varColorNavSecondary',
                                    '$varColorTextPrimary',
                                    '$varColorTextSecondary',
                                    '$varColorBorderPrimary',
                                    '$varColorBorderSecondary',
                                    '$varColorPanelPrimary',
                                    '$varColorPanelSecondary',
                                    '$varColorChoicePrimary',
                                    '$varColorChoiceSecondary',
                                    '$varColorFooterPrimary',
                                    '$varColorFooterSecondary',
                                    '$varColorTitle',
                                    '$varColorHeading',
                                    '$varColorLogo',
                                ]],
                            ]],
                        ]],
                    ]]
                ]],
                ['edit_game_form_code_title', [
                    ['row', [
                        '$mainJs',
                    ]]
                ]],
                ['edit_game_form_location_title', [
                    ['column', [
                        '$locationName',
                        '$locationPresentation',
                        '$locationDescription',
                        '$locationStreet',
                        '$locationComplement',
                        '$locationCity',
                        '$locationZipCode',
                        '$locationState',
                        '$locationCountry',
                        '$locationWebsiteUrl',
                        '$locationFacebookUrl',
                        '$locationPhone',
                        '$locationEmail',
                        '$locationLogoImage',
                        '$locationMainImage',
                        '$locationMainVideoUrl',
                        '$locationMainAudioUrl',
                        '$locationLatitude',
                        '$locationLongitude',
                        '$locationTwitterUrl',
                        '$locationYoutubeUrl',
                    ]]
                ]],
                ['edit_game_form_godfather_title', [
                    ['row', [
                        ['column', [
                            '$godfatherImage',
                            '$godfatherVideoUrl',
                            '$godfatherTitle',
                            '$godfatherSubtitle',
                        ]],
                        ['column', [
                            '$godfather2Image',
                            '$godfather2VideoUrl',
                            '$godfather2Title',
                            '$godfather2Subtitle',
                        ]],
                        ['column', [
                            '$godfather3Image',
                            '$godfather3VideoUrl',
                            '$godfather3Title',
                            '$godfather3Subtitle',
                        ]],
                    ]]
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        extraToolbar: true,
        body: true,
        properties: [],
    },
    list: {
        columns: [
            { id: 'createdAt', label: 'column_createdat_label', flex: 0, width: 160, component: 'date' } as any,
            { id: 'locale', label: 'column_locale_label', flex: 0, width: 80, component: 'locale' } as any,
            { id: 'code', label: 'column_code_label', flex: 0, width: 100, component: 'game_code_self' } as any,
            { id: 'status', label: 'column_status_label', flex: 0, width: 120, component: 'game_status' } as any,
            { id: 'name', label: 'column_name_label', component: 'game_name_self' } as any,
            { id: 'ticketPriceAmount', type: 'number', label: 'column_ticket_price_label', flex: 0, width: 120, component: 'ticket_price_amount' } as any,
            { id: 'plan', label: 'column_plan_label', flex: 0, width: 120, component: 'plan' } as any,
            { id: 'statTickets', type: 'number', label: 'column_game_stat_tickets_label', flex: 0, width: 120 } as any,
            { id: 'statPaidSales', type: 'number', label: 'column_game_stat_paid_sales_label', flex: 0, width: 120 } as any,
            { id: 'statPaidAmount', type: 'number', label: 'column_game_stat_paid_amount_label', flex: 0, width: 120, component: 'stat_paid_amount' } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete'],
        filters: {
            running: {},
            runningWithSales: {},
            withSales: {},
            top: {},
            freeTicket: {},
            helloasso: {},
            assofree: {},
            assofree1k: {},
            custom50k: {},
            flex: {},
            paused: {},
            dateReached: {},
            closed: {},
            drawn: {},
            ads: {},
            all: {},
        },
    },
    types: {
        bunch: {
            plural: 'bunches',
            new: {},
            list: {
                columns: [
                    { id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 170 } as any,
                    { id: 'code', label: 'column_code_label', flex: 0, width: 95 } as any,
                    { id: 'name', label: 'column_name_label', flex: 1 } as any,
                    { id: 'rank', type: 'number', label: 'column_rank_label', flex: 0, width: 100 } as any,
                    { id: 'quantity', type: 'number', label: 'column_quantity_label', flex: 0, width: 100 } as any,
                    { id: 'priceAmount', type: 'number', label: 'column_price_label', flex: 0, width: 100 } as any,
                ],
                globalActions: ['add'],
                actions: ['up', 'down', 'display', 'edit', 'delete'],
            },
        },
        sale: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 150 },
                    { id: 'status', label: 'column_status_label', flex: 0, width: 100 },
                    { id: 'owner', label: 'column_owner_label', },
                    { id: 'paymentAmount', type: 'number', label: 'column_paymentamount_label', flex: 0, width: 115, format: 'priceFromCents' },
                    { id: 'tickets', type: 'number', label: 'column_tickets_label', flex: 0, width: 110 },
                    { id: 'donation', type: 'number', label: 'column_donation_label', format: 'priceFromCents', flex: 0, width: 100 },
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
        project: {
            new: {},
            list: {
                columns: [
                    { id: 'code', label: 'column_code_label', flex: 0, width: 200 } as any,
                    { id: 'name', label: 'column_name_label' } as any,
                    { id: 'organizationCode', label: 'column_organization_code_label', flex: 0, width: 150 },
                    { id: 'rank', type: 'number', label: 'column_rank_label' },
                ],
                globalActions: ['add'],
                actions: ['up', 'down', 'display', 'edit', 'delete'],
            },
        },
        ticket: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'code', label: 'column_code_label', flex: 0, width: 100 } as any,
                    { id: 'customCode', label: 'column_customcode_label', flex: 0, width: 100 } as any,
                    { id: 'price', type: 'number', label: 'column_price_label', flex: 0, width: 110, format: 'priceFromCents' },
                    { id: 'currency', label: 'column_currency_label', flex: 0, width: 110 },
                    { id: 'owner', label: 'column_owner_label' },
                    { id: 'organizationCode', label: 'column_organization_code_label', flex: 0, width: 140 },
                    { id: 'organizationName', label: 'column_organization_name_label' },
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
        promise: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'createdAt', label: 'column_createdat_label', component: 'date' },
                    { id: 'status', label: 'column_status_label' },
                    { id: 'owner', label: 'column_owner_label', },
                    { id: 'tickets', type: 'number', label: 'column_tickets_label', flex: 0, width: 100},
                    { id: 'donation', type: 'number', label: 'column_donation_label', format: 'priceFromCents', flex: 0, width: 100 },
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
        godfather: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'code', label: 'column_code_label', flex: 0, width: 100 } as any,
                    { id: 'email', label: 'column_email_label' },
                    { id: 'phone', label: 'column_phone_label' }
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
        beneficiary: {
            plural: 'beneficiaries',
            new: {},
            list: {
                columns: [
                    { id: 'code', label: 'column_code_label', flex: 0, width: 200 } as any,
                    { id: 'name', label: 'column_name_label' } as any,
                    { id: 'organizationCode', label: 'column_organization_code_label', flex: 0, width: 150 },
                    { id: 'rank', type: 'number', label: 'column_rank_label' },
                ],
                globalActions: ['add'],
                actions: ['up', 'down', 'display', 'edit', 'delete'],
            },
        },
        sponsoring: {
            new: {},
            list: {
                columns: [
                    { id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 200 },
                    { id: 'code', label: 'column_code_label', flex: 0, width: 120 } as any,
                    { id: 'name', label: 'column_name_label', flex: 0, width: 150 } as any,
                    { id: 'organizationCode', label: 'column_organization_code_label', flex: 0, width: 120 } as any,
                    { id: 'rank', type: 'number', label: 'column_rank_label' },
                ],
                globalActions: ['add'],
                actions: ['up', 'down', 'display', 'edit', 'delete'],
            },
        },
        sellergroup: {
            new: {},
            list: {
                columns: [
                    { id: 'groupingCode', label: 'column_grouping_label', component: 'grouping' },
                    { id: 'name', label: 'column_name_label' },
                    { id: 'email', label: 'column_email_label' },
                    { id: 'rank', type: 'number', label: 'column_rank_label' },
                ],
                globalActions: ['add'],
                actions: ['up', 'down', 'display', 'edit', 'delete'],
            },
        },
        seller: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'sellergroupCode', label: 'column_sellergroup_code_label' },
                    { id: 'email', label: 'column_email_label' },
                    { id: 'lastName', label: 'column_lastname_label' },
                    { id: 'firstName', label: 'column_firstname_label' },
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
        input: {
            new: {},
            list: {
                columns: [
                    { id: 'code', label: 'column_code_label', flex: 0, width: 200 } as any,
                    { id: 'name', label: 'column_name_label' } as any,
                    { id: 'type', label: 'column_type_label' } as any,
                    { id: 'organizationCode', label: 'column_organization_code_label', flex: 0, width: 150 },
                    { id: 'rank', type: 'number', label: 'column_rank_label' },
                ],
                globalActions: ['add'],
                actions: ['up', 'down', 'display', 'edit', 'delete'],
            },
        },
        pack: {
            new: {},
            list: {
                columns: [
                    { id: 'code', label: 'column_code_label', flex: 0, width: 200 } as any,
                    { id: 'name', label: 'column_name_label' } as any,
                    { id: 'tickets', type: 'number', label: 'column_tickets_label' } as any,
                    { id: 'donation', type: 'number', label: 'column_ticket_books_label', format: 'priceFromCents' } as any,
                    { id: 'ticketBooks', type: 'number', label: 'column_donation_label' } as any,
                    { id: 'organizationCode', label: 'column_organization_code_label', flex: 0, width: 150 },
                    { id: 'rank', type: 'number', label: 'column_rank_label' },
                ],
                globalActions: ['add'],
                actions: ['up', 'down', 'display', 'edit', 'delete'],
            },
        },
        integration: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'id', label: 'column_id_label' },
                    { id: 'name', label: 'column_name_label' },
                    { id: 'organizationName', label: 'column_organization_label' },
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
        keyword: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'code', label: 'column_code_label', flex: 0, width: 200 } as any,
                    { id: 'organizationCode', label: 'column_organization_code_label', flex: 0, width: 150 },
                    { id: 'organizationName', label: 'column_organization_label' },
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
        draw: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'key', label: 'column_key_label', flex: 0, width: 200 } as any,
                    { id: 'gameCode', label: 'column_game_code_label' },
                    { id: 'gameName', label: 'column_game_label' },
                    { id: 'strategy', label: 'column_strategy_label' },
                    { id: 'draft', type: 'boolean', label: 'column_draft_label' },
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
        winner: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'code', label: 'column_code_label', flex: 0, width: 200 } as any,
                    { id: 'gameCode', label: 'column_game_code_label' },
                    { id: 'gameName', label: 'column_game_label' },
                    { id: 'rank', type: 'number', label: 'column_rank_label' },
                    { id: 'bunch', label: 'column_bunch_label' },
                    { id: 'email', label: 'column_email_label' },
                    { id: 'phone', label: 'column_phone_label' },
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
        notification: {
            list: {
                moveable: false,
                columns: [
                    { id: 'createdAt', label: 'column_createdat_label', flex: 0, width: 200, component: 'date' },
                    { id: 'type', label: 'column_type_label', flex: 0, width: 70 },
                    { id: 'status', label: 'column_status_label', flex: 0, width: 120 },
                    { id: 'recipient', label: 'column_recipient_label', flex: 0, width: 200 },
                    { id: 'subject', label: 'column_subject_label' },
                ],
                globalActions: [],
                actions: ['display', 'edit', 'delete'],
            },
        },
        gimport: {
            list: {
                moveable: false,
                columns: [
                    { id: 'status', label: 'column_status_label' },
                    { id: 'type', label: 'column_type_label' },
                    { id: 'gameName', label: 'column_game_label' },
                    { id: 'organizationName', label: 'column_organization_label' },
                ],
                globalActions: [],
                actions: ['display', 'edit', 'delete'],
            },
        },
        greport: {
            list: {
                moveable: false,
                columns: [
                    { id: 'period', label: 'column_period_label' },
                    { id: 'type', label: 'column_type_label' },
                    { id: 'paidSales', type: 'number', label: 'column_paidsales_label' },
                    { id: 'paidTickets', type: 'number', label: 'column_paidtickets_label' },
                    { id: 'paymentAmount', type: 'number', label: 'column_paymentamount_label', format: 'priceFromCents' },
                    { id: 'promisedSales', type: 'number', label: 'column_promisedsales_label' },
                ],
                globalActions: [],
                actions: ['display', 'edit', 'delete'],
            },
        },
        gversion: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'gameCode', label: 'column_code_label', flex: 0, width: 120 } as any,
                    { id: 'code', label: 'column_code_label', flex: 0, width: 130 } as any,
                    { id: 'type', label: 'column_type_label', flex: 0, width: 100 } as any,
                    { id: 'locale', label: 'column_locale_label', flex: 0, width: 100 } as any,
                    { id: 'gameName', label: 'column_game_label', flex: 0, width: 120 } as any,
                    { id: 'organizationCode', label: 'column_organization_label', flex: 1 } as any,
                    { id: 'organizationName', label: 'column_organization_label', flex: 1 } as any,
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
        book: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'code', label: 'column_code_label', flex: 0, width: 100 } as any,
                    { id: 'game', label: 'column_game_label', flex: 0, width: 300 } as any,
                    { id: 'paymentStatus', label: 'column_payment_status_label' },
                    { id: 'sellergroup', label: 'column_sellergroup_code_label' },
                    { id: 'customCode', label: 'column_customcode_label' },
                    { id: 'attributionStatus', label: 'column_attribution_status_label' },
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
        grouping: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'code', label: 'column_code_label' } as any,
                    { id: 'gameCode', label: 'column_game_code_label' } as any,
                    { id: 'email', label: 'column_email_label' } as any,
                    { id: 'name', label: 'column_name_label' } as any,
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
        translation: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'code', label: 'column_code_label' } as any,
                    { id: 'type', label: 'column_type_label' } as any,
                    { id: 'domain', label: 'column_domain_label' } as any,
                    { id: 'text', label: 'column_text_label' } as any,
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
    },
};

export default t;
